<template>
    <div class="error-box">
        <div class="error_content">
            <span class="error_icon">i</span>
            <span class="error_text" v-if="message" v-html="message"></span>
            <div class="error_text" v-else>
                <slot></slot>
            </div>
            <button class="error_button" @click="$emit('close')">确定</button>
        </div>
    </div>

</template>

<script>
export default {
    name: "error",
    props: {
        message: {
            default: '',
            type: String
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'
.show
    display

.hidden
    display none

.error-box
    position fixed
    background-color rgba(0, 0, 0, 0.6)
    top 0
    right 0
    bottom 0
    left 0
    display flex
    flex-direction column
    align-items center
    justify-content center
    z-index 99

    //position absolute
    //margin 396px 50px 50px
    //z-index 99

    .error_content
        width 650px
        min-height 462px
        background #FFFFFF
        box-shadow 0 17px 35px 0 rgba(0, 41, 30, 0.08)
        border-radius 60px
        padding 30px 0
        box-sizing border-box
        display flex
        flex-direction column
        justify-content space-around
        align-items center

        .error_icon
            width 80px
            height 80px
            border-radius 50%
            text-align center
            line-height 80px
            background $error
            color white
            font-size 50px
            font-weight bold

        .error_text
            display block
            margin 0 20px
            font-size: 30px
            line-height 60px
            color $error
            text-align center

        .error_button
            buttonSet()
            color white
            width 300px
</style>
