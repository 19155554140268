//验证姓名/地址不为空
// validName(){
//   var nameReg = /^$/ig;
//   var spaceReg = /^\s+$/ig;
//   var name = this.staff.entourageItems[i].name
//   if(!nameReg.test(name) && !spaceReg.test(name)){
//     this.nameFlag[i] = true;
//     return true;
//   }else{
//     this.nameFlag[i] = false;
//     return false;
//   }
// },

//验证身份证号
export function valIdCard(input) {
    if (!input || input.length === 0) return false;
    // const idCardReg = /^[1-9](\d{5})(19|20)(\d{2})((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)(\d{3})(\d|X|x)$/ig;
    const idCardReg = /^(([a-z]+\d+)|([1-9](\d{5})(19|20)(\d{2})((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)(\d{3})(\d|X|x)))$/ig;
    return idCardReg.test(input);
}

//验证手机号
export function valTel(input) {
    if (!input || input.length === 0) return false;
    // const telReg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/ig;
    const telReg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
    return telReg.test(input);
}

//验证员工号
export function valUserid(input) {
    const useridReg = /^\i\d{6}$/ig;
    return useridReg.test(input);
}

//验证邮箱
export function valEmail(input) {
    const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ig;
    return emailReg.test(input);
}
